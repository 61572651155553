import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import mergeEdges from '../../../utils/mergeEdges'
import { IProp } from './types'
import View from './view'

export default function queryUpcomingEvents({ events }: any) {
  return (
    <StaticQuery
      query={graphql`
        query UpcomingEvents {
          allFoundationYaml {
            edges {
              node {
                events {
                  upcoming {
                    title
                    description
                  }
                }
              }
            }
          }
        }
      `}
      render={(data: IProp) => {
        const upcomingEvents = mergeEdges(data.allFoundationYaml)

        if (!upcomingEvents || !events) {
          return null
        }

        const newData = {
          ...upcomingEvents.events,
          events,
        }

        return <View data={newData} />
      }}
    />
  )
}
