import * as React from 'react'

import Events from '../components/Events/Upcoming'
import LatestPosts from '../components/LatestPosts'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'
import CalendarApi from '../components/shared/CalendarApi'
import Layout from '../layouts'

const EventsPage: React.FC = () => {
  return (
    <Layout>
      <SEO />
      <CalendarApi dataSource="general">
        {events => <Events events={events} />}
      </CalendarApi>
      <LatestPosts
        amount={6}
        message="events"
        categories={['Events', 'Videos']}
      />
      <Newsletter />
    </Layout>
  )
}

export default EventsPage
