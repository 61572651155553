import * as React from 'react'

import Card from '../Card'
import { Box, Flex } from '../Helpers'
import {
  Text,
  TextBold,
  Title,
  VerticalSeparator,
  HorizontalLine,
} from '../Typography'
import Link from '../../shared/Link'
import Modal from '../Modal'

const plusAsset = require('../../../images/icons/plus.svg')
const calendarGoogleIcon = require('../../../images/icons/calendarGoogle.svg')
const calendarAppleIcon = require('../../../images/icons/calendarApple.svg')
const calendarOutlookIcon = require('../../../images/icons/calendarOutlook.svg')

type IData = Array<{
  [key: string]: any
}>

interface IProps {
  calendarAddressLinkGoogle: string
  calendarAddressLinkICS: string
  calendarDescription: string
  calendarLocation: string
  calendarTitle: string
  endDay: string
  endDayName: string
  endHours: string
  endMonth: string
  endYear: number
  isOneDayEvent: boolean
  isSameMonth: boolean
  isSameYear: boolean
  startDay: string
  startDayName: string
  startHours: string
  startMonth: string
  startYear: number
  timeZoneGMT: string
}

const ModalContent = ({
  calendarAddressLinkGoogle,
  calendarAddressLinkICS,
  calendarDescription,
  calendarLocation,
  calendarTitle,
  endDay,
  endDayName,
  endHours,
  endMonth,
  endYear,
  isOneDayEvent,
  isSameMonth,
  isSameYear,
  startDay,
  startDayName,
  startHours,
  startMonth,
  startYear,
  timeZoneGMT,
  toggleModal,
}: IProps & { toggleModal: () => any }) => {
  return (
    <Modal onClickOutside={toggleModal}>
      <Flex flexDirection="column" padding={['0.5em', '2em']}>
        <Title
          textAlign="center"
          mb="0.5em"
          fontSize="1.250em"
          fontWeight="400"
        >
          {calendarTitle}
        </Title>
        <Text
          textAlign="center"
          mb="0.25em"
          color="greyMedium"
          fontSize="0.875em"
        >
          {isOneDayEvent ? (
            <>
              {startDayName} {startDay} {startMonth} {startYear}
            </>
          ) : (
            <>
              {startDayName} {startDay} {!isSameMonth && startMonth}
              {!isSameYear && startYear} to {endDayName} {endDay} {endMonth}
              {endYear}
            </>
          )}
          <VerticalSeparator color="grey" />
          {startHours} - {endHours} {timeZoneGMT}
        </Text>
        <Text textAlign="center" color="greyMedium" fontSize="0.875em">
          {calendarLocation}
        </Text>
        <HorizontalLine margin={['0.5em 0', '1.5em 0']} />

        <Text mb="1em" fontSize="0.875em">
          {calendarDescription}
        </Text>

        <Flex pt={['0', '2em']} justifyContent="center">
          <Flex
            alignItems="start"
            flexDirection={['column', 'row']}
            justifyContent="space-between"
            width={['auto', '100%']}
          >
            <Flex alignItems="center">
              <img
                alt="calendar Google Icon"
                src={calendarGoogleIcon}
                width="22px"
              />
              <Link
                color="blue"
                to={calendarAddressLinkGoogle}
                childrenProps={{ justifyContent: 'center' }}
                ml=".5em"
              >
                Add to Google
              </Link>
            </Flex>
            <Flex alignItems="center" padding={[' 0.375em 0', '0']}>
              <img
                alt="calendar Apple Icon"
                src={calendarAppleIcon}
                width="22px"
              />
              <Link
                color="blue"
                to={calendarAddressLinkICS}
                childrenProps={{ justifyContent: 'center' }}
                ml=".5em"
              >
                Add to iCal
              </Link>
            </Flex>
            <Flex alignItems="center">
              <img
                alt="calendar Outlook Icon"
                src={calendarOutlookIcon}
                width="22px"
              />
              <Link
                color="blue"
                to={calendarAddressLinkICS}
                childrenProps={{ justifyContent: 'center' }}
                ml=".5em"
              >
                Add to Outlook
              </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default function renderEvents(data: IData) {
  if (data.length === 0) {
    return null
  }

  const [isModalOpen, setModalOpen] = React.useState(false)
  const [selectedModalData, setSelectedModalData] = React.useState<IProps | {}>(
    {}
  )
  const toggleModal = () => setModalOpen(!isModalOpen)

  return (
    <Flex width="100%" maxWidth="747px" flexDirection="column">
      {data.map((el: any) => {
        const {
          id,
          end: { dateTime: finalDateTime, date: finalDate },
          start: { dateTime, date },
          summary: calendarTitle,
          description: calendarDescription,
          location: calendarLocation,
          htmlLink: calendarAddressLinkGoogle,
          organizer,
        } = el

        const calendarAddressLinkICS = `https://calendar.google.com/calendar/ical/${
          organizer.email
        }/public/basic.ics`

        const startHour = ('0' + new Date(dateTime || date).getHours()).slice(
          -2
        )
        const startMinute = (
          '0' + new Date(dateTime || date).getMinutes()
        ).slice(-2)

        const startHours = `${startHour}:${startMinute}`

        const endHour = (
          '0' + new Date(finalDateTime || finalDate).getHours()
        ).slice(-2)

        const endMinute = (
          '0' + new Date(finalDateTime || finalDate).getMinutes()
        ).slice(-2)

        const endHours = `${endHour}:${endMinute}`

        const timeZoneOffset =
          new Date(dateTime || date).getTimezoneOffset() / 60

        const timeZoneGMT = `(GMT${timeZoneOffset * -1 < 0 ? '-' : '+'}${
          timeZoneOffset < 10 ? '0' : ''
        }${Math.abs(timeZoneOffset)})`

        const startDayName = new Date(dateTime || date).toLocaleDateString(
          'en-US',
          {
            weekday: 'short',
          }
        )

        const startDay = ('0' + new Date(dateTime || date).getUTCDate()).slice(
          -2
        )

        const startMonth = new Date(dateTime || date).toLocaleDateString(
          'en-US',
          {
            month: 'short',
          }
        )
        const startYear = new Date(dateTime || date).getFullYear()

        const endDayName = new Date(
          finalDateTime || finalDate
        ).toLocaleDateString('en-US', {
          weekday: 'short',
        })

        const endDay = (
          '0' + new Date(finalDateTime || finalDate).getUTCDate()
        ).slice(-2)

        const endMonth = new Date(
          finalDateTime || finalDate
        ).toLocaleDateString('en-US', {
          month: 'short',
        })
        const endYear = new Date(finalDateTime || finalDate).getFullYear()

        const isOneDayEvent =
          startDay === endDay &&
          startMonth === endMonth &&
          startYear === endYear

        const isSameMonth = startMonth === endMonth
        const isSameYear = startYear === endYear

        return (
          <Card
            clickable
            key={id}
            p="1.5em"
            width="100%"
            mb="1em"
            as="button"
            onClick={() => {
              setSelectedModalData({
                calendarAddressLinkGoogle,
                calendarAddressLinkICS,
                calendarDescription,
                calendarLocation,
                calendarTitle,
                endDay,
                endDayName,
                endHours,
                endMonth,
                endYear,
                isOneDayEvent,
                isSameMonth,
                isSameYear,
                startDay,
                startDayName,
                startHours,
                startMonth,
                startYear,
                timeZoneGMT,
              })
              toggleModal()
            }}
          >
            <Flex alignItems="center">
              <Box width="100%" mr={['0', '0.5em']}>
                <Flex alignItems="flex-start">
                  <Text color="grey" fontSize="0.875em" textAlign="left">
                    {isOneDayEvent ? (
                      <>
                        {startDayName} {startDay} {startMonth} {startYear}
                      </>
                    ) : (
                      <>
                        {startDayName} {startDay} {!isSameMonth && startMonth}{' '}
                        {!isSameYear && startYear} to {endDayName} {endDay}{' '}
                        {endMonth} {endYear}
                      </>
                    )}
                    <VerticalSeparator color="grey" />
                    {startHours} {timeZoneGMT}
                  </Text>
                </Flex>
                <Flex alignItems="flex-start " mt="0.125em">
                  <TextBold fontSize="1.125em" textAlign="left">
                    {calendarTitle}
                  </TextBold>
                </Flex>

                <Flex alignItems="flex-start">
                  <Box mt="1em">
                    <Link
                      color="blue"
                      to="/hub-bcn"
                      withIcon="location"
                      positionIcon="left"
                      isHoverAnimationActive={false}
                      fontSize="0.875em"
                      textAlign="left"
                    >
                      {calendarLocation}
                    </Link>
                  </Box>
                </Flex>
              </Box>

              <Box mr={['0', '1em']}>
                <img alt="plus sign" src={plusAsset} />
              </Box>
            </Flex>
          </Card>
        )
      })}

      {isModalOpen && (
        <ModalContent
          {...selectedModalData as IProps}
          toggleModal={toggleModal}
        />
      )}
    </Flex>
  )
}
