import * as React from 'react'

import { endpoint, googleCalendarIds } from '../../../constants'
import { IAppProps, IState } from './types'

export default class IApp extends React.PureComponent<IAppProps, IState> {
  windowGlobal = typeof window !== 'undefined' && (window as any)

  constructor(props: IAppProps) {
    super(props)

    this.state = {
      calendarId: googleCalendarIds[props.dataSource],
      events: [],
    }
  }

  componentDidMount() {
    this.checkForEvents()
  }

  checkForEvents() {
    const { calendarId } = this.state
    const self = this

    fetch(`${endpoint}/google?calendarId=${calendarId}`)
      .then(response => response.json())
      .then(json => {
        self.setState({ events: json.items })
      })
      .catch(err => new Error(err))
  }

  render() {
    const { events } = this.state

    if (!events.length) {
      return null
    }

    return this.props.children(events)
  }
}
