import * as React from 'react'

import renderEvents from '../../shared/Events/card'
import { Container } from '../../shared/Grid'
import { Box, Flex } from '../../shared/Helpers'
import { Description, MainTitle } from '../../shared/Typography'
import { IEvents } from './types'

interface IProps {
  data: {
    upcoming: IEvents
    [key: string]: any
  }
}

export default function renderUpcomingEvents({ data }: IProps) {
  const padd = [0, '0 6em', '0 16em']
  const textAlign = ['left', 'center']

  const {
    upcoming: { description, title },
  } = data

  if (data.events.length === 0) {
    return null
  }

  return (
    <Container>
      <Flex flexDirection="column" mt="4em" mb="2em">
        <Box p={padd} width="100%">
          <Flex width="100%" justifyContent="center">
            <MainTitle textAlign={textAlign} mb="1rem">
              {title}
            </MainTitle>
          </Flex>
          <Description color="greyMedium" textAlign={textAlign} mb="1rem">
            {description}
          </Description>
        </Box>
      </Flex>
      <Flex width="100%" justifyContent="center" mb={['4em', '8em']}>
        {renderEvents(data.events)}
      </Flex>
    </Container>
  )
}
